import { Box, Collapse, Grid } from "@mui/material";
import {
    countBy, filter,
    find, includes, indexOf,
    isEmpty,
    join, map, max, noop
} from "lodash";
import React, { useMemo, useState } from "react";
import {
    isRadioButtonGroup,
    shouldEnableMultiSelect,
    priceForSequenceOption
} from "../../utils/menuItemOrderData";
import Card from "../Card";
import ExpandMore from "../expand-more";
import TastePreferenceDecorator from "./TastePreferenceDecorator";
import * as styles from './index.module.scss';

export default function OrderItemComponent(props) {
  const { orderItemComponent, onChange = noop, posInnerMenuId } = props;
  const {
    appStyles,
    pageContext: {
      business: { currencySymbol, allergens },
      kioskMode,
    },
    T,
    firstComponentWithError,
    animateErrorElement,
  } = props;

  const {
    maxNumOfMarkedOptionsIncluded,
    minNumOfMarkedComponentOptions,
    maxNumOfMarkedOptions,
    chosenOrderItemComponentOptions,
    priceAdditional,
    sequencePrices
  } = orderItemComponent;

  const [displayRadioButtonGroup, enableMultiSelect] = useMemo(
    () => [
      isRadioButtonGroup(orderItemComponent),
      shouldEnableMultiSelect(orderItemComponent),
    ],
    [JSON.stringify(orderItemComponent)],
  );
  const limit = useMemo(
    () => Math.max(maxNumOfMarkedOptions, maxNumOfMarkedOptionsIncluded),
    [maxNumOfMarkedOptions, maxNumOfMarkedOptionsIncluded],
  );

  const getMaxForComponentOption = (componentOption) => {
    return max([maxNumOfMarkedOptions, maxNumOfMarkedOptionsIncluded]) > -1
      ? max([maxNumOfMarkedOptions, maxNumOfMarkedOptionsIncluded]) -
          filter(
            chosenOrderItemComponentOptions,
            (id) => id !== componentOption.id,
          ).length
      : 100;
  };

  const ComponentOptionPrice = ({ componentOption, addPlusSign }) => {
    const chosenOptionPosInnerMenuItem = find(
      componentOption.posInnerMenuSpecificPrices,
      { posInnerMenuId },
    );

    const componentOptionPrice =
      chosenOptionPosInnerMenuItem &&
      typeof chosenOptionPosInnerMenuItem.price === "number"
        ? chosenOptionPosInnerMenuItem.price
        : componentOption.price;

    const indexInChosen = indexOf(
      chosenOrderItemComponentOptions,
      componentOption.id,
    );
    const showPriceAdditional =
      !isEmpty(orderItemComponent.chosenOrderItemComponentOptions) &&
      orderItemComponent.chosenOrderItemComponentOptions.length >=
        maxNumOfMarkedOptionsIncluded &&
      (indexInChosen >= maxNumOfMarkedOptionsIncluded || indexInChosen === -1);

    const price =
      (componentOptionPrice || 0) +
      ((showPriceAdditional && priceAdditional) || 0) +
      (priceForSequenceOption(componentOption, orderItemComponent));
    
     if (appStyles.hidePlusSign && price < 0){
        return <span className={styles.ComponentOptionPrice}> {currencySymbol}{price * -1} off</span>;
     }
    return price > 0 ? (
      <span className={styles.ComponentOptionPrice}>
        {addPlusSign && "+"}
        {currencySymbol}
        {appStyles.pricesFixedPoint
          ? price.toFixed(appStyles.pricesFixedPoint)
          : price}
      </span>
    ) : null;
  };

  const shouldShowPlusSign = !(
    minNumOfMarkedComponentOptions > 0 &&
    !find(
      orderItemComponent.componentOptions,
      (componentOption) => !componentOption.price,
    )
  );

  const [expanded, setExpanded] = useState(
    (appStyles.expandModifiersIfLessThan > 0 &&
      appStyles.expandModifiersIfLessThan >
        orderItemComponent.componentOptions.length) ||
      minNumOfMarkedComponentOptions > 0 ||
      !appStyles.collapseModifiersByDefault,
  );

  const choesenCounter = countBy(
    orderItemComponent.chosenOrderItemComponentOptions,
  );

  const chosenTitles = useMemo(
    () =>
      map(
        filter(orderItemComponent.componentOptions, ({ id }) =>
          includes(orderItemComponent.chosenOrderItemComponentOptions, id),
        ),
        ({ title, id }) => {
          const count = choesenCounter[id];
          if (count > 1) {
            return `${count} × ${T(title)}`;
          }
          return T(title);
        },
      ),
    [JSON.stringify(orderItemComponent.chosenOrderItemComponentOptions)],
  );

  const mainHeader = includes(orderItemComponent.title, "(") ? orderItemComponent.title.substring(0, indexOf(orderItemComponent.title, "(")):orderItemComponent.title;
  const sideHeaderNote = includes(orderItemComponent.title, "(") ? orderItemComponent.title.substring(indexOf(orderItemComponent.title, "(")):null;
  return (
    <Card
      style={{ paddingBottom: expanded ? 10 : 10, cursor: "pointer" }}
      appStyles={appStyles}
      key={orderItemComponent.id}
      refEl={props.refEl}
      animateError={firstComponentWithError && animateErrorElement}
      kioskMode={kioskMode}
      hasErrors={firstComponentWithError}
    >
      <Card.Header onClick={() => setExpanded(!expanded)}>
        <Grid container direction="column" spacing={1} alignItems="flex-start">
          <Grid item container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Card.Header.Title appStyles={appStyles}>
                {T(mainHeader)}
              </Card.Header.Title>
              {sideHeaderNote && <Card.Header.SideNote appStyles={appStyles}>
                {T(sideHeaderNote)}
              </Card.Header.SideNote>}
            </Grid>
            <Grid item>
              <Card.Header.SideNote appStyles={appStyles}>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  wrap="nowrap"
                >
                  {limit > 0 && !displayRadioButtonGroup && (
                    <Grid item>
                      <small>
                        {T("max.")} {limit}
                      </small>
                    </Grid>
                  )}
                  {minNumOfMarkedComponentOptions > 0 && (
                    <Grid item>
                      <strong style={{ color: appStyles.actionColor }}>
                        {T("Required")}
                      </strong>
                    </Grid>
                  )}
                  <Grid item>
                    <Box sx={{ marginRight: 0.5 }}>
                      <ExpandMore
                        expanded={expanded}
                        handleExpandClick={() => setExpanded(!expanded)}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Card.Header.SideNote>
            </Grid>
          </Grid>
          <Grid item>
            <Collapse in={!expanded}>
              <small>{join(chosenTitles, ", ")}</small>
            </Collapse>
          </Grid>
        </Grid>
      </Card.Header>
      <Collapse in={expanded}>
        <Card.Content>
          {displayRadioButtonGroup ? (
            <Card.Content.RadioGroup
              value={
                Array.isArray(
                  orderItemComponent.chosenOrderItemComponentOptions,
                ) && orderItemComponent.chosenOrderItemComponentOptions.length
                  ? orderItemComponent.chosenOrderItemComponentOptions[0]
                  : null
              }
              onChange={(chosenOrderItemComponentOption) =>
                onChange({
                  ...orderItemComponent,
                  chosenOrderItemComponentOptions: [
                    chosenOrderItemComponentOption,
                  ],
                })
              }
              wrap
              appStyles={appStyles}
            >
              {map(
                orderItemComponent.componentOptions,
                (componentOption, index) => (
                  <Card.Content.RadioOption
                    iconSize={16}
                    iconInnerSize={16}
                    pointColor={appStyles.actionColor}
                    value={componentOption.id}
                    key={componentOption.id}
                  >
                    <span style={{ paddingTop: 4, width: "100%" }}>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TastePreferenceDecorator
                          title={T(componentOption.title)}
                          appStyles={appStyles}
                          allergens={allergens}
                        />
                        <ComponentOptionPrice
                          componentOption={componentOption}
                          index={index}
                          addPlusSign={shouldShowPlusSign && appStyles.hidePlusSign !== true}
                        />
                      </span>
                      {componentOption.description && (
                        <span>
                          <small style={{ color: "#555" }}>
                            {T(componentOption.description)}
                          </small>
                        </span>
                      )}
                    </span>
                  </Card.Content.RadioOption>
                ),
              )}
            </Card.Content.RadioGroup>
          ) : enableMultiSelect ? (
            <Card.Content.CardNumericGroup
              name={T(orderItemComponent.title)}
              limit={limit < 0 ? undefined : limit}
              checked={orderItemComponent.chosenOrderItemComponentOptions || []}
              onChange={(chosenOrderItemComponentOptions) =>
                onChange({
                  ...orderItemComponent,
                  chosenOrderItemComponentOptions,
                })
              }
              wrap
              appStyles={appStyles}
            >
              {map(
                orderItemComponent.componentOptions,
                (componentOption, index) => (
                  <Card.Content.CardNumericInput
                    T={T}
                    key={componentOption.id}
                    value={componentOption.id}
                    values={orderItemComponent.chosenOrderItemComponentOptions}
                    appStyles={appStyles}
                    max={getMaxForComponentOption(componentOption)}
                  >
                    <span style={{ paddingTop: 4, width: "100%" }}>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TastePreferenceDecorator
                          title={T(componentOption.title)}
                          appStyles={appStyles}
                          allergens={allergens}
                        />
                        <ComponentOptionPrice
                          componentOption={componentOption}
                          index={index}
                          addPlusSign={appStyles.hidePlusSign !== true}
                        />
                      </span>
                      {componentOption.description && (
                        <span>
                          <small>{T(componentOption.description)}</small>
                        </span>
                      )}
                    </span>
                  </Card.Content.CardNumericInput>
                ),
              )}
            </Card.Content.CardNumericGroup>
          ) : (
            <Card.Content.CheckboxGroup
              name={T(orderItemComponent.title)}
              limit={limit < 0 ? undefined : limit}
              checked={orderItemComponent.chosenOrderItemComponentOptions || []}
              onChange={(chosenOrderItemComponentOptions) =>
                onChange({
                  ...orderItemComponent,
                  chosenOrderItemComponentOptions,
                })
              }
              wrap
              appStyles={appStyles}
            >
              {map(
                orderItemComponent.componentOptions,
                (componentOption, index) => (
                  <Card.Content.CheckboxOption
                    iconSize={16}
                    iconInnerSize={16}
                    pointColor={appStyles.actionColor}
                    value={componentOption.id}
                    key={componentOption.id}
                  >
                    <span style={{ paddingTop: 4, width: "100%" }}>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TastePreferenceDecorator
                          title={T(componentOption.title)}
                          appStyles={appStyles}
                          allergens={allergens}
                        />
                        <ComponentOptionPrice
                          componentOption={componentOption}
                          index={index}
                          addPlusSign={appStyles.hidePlusSign !== true}
                        />
                      </span>
                      {componentOption.description && (
                        <span>
                          <small style={{ color: "#555" }}>
                            {T(componentOption.description)}
                          </small>
                        </span>
                      )}
                    </span>
                  </Card.Content.CheckboxOption>
                ),
              )}
            </Card.Content.CheckboxGroup>
          )}
        </Card.Content>
      </Collapse>
    </Card>
  );
}
