import React from "react";
import _ from "lodash";
import Card from "../Card";

export default ({
  sizeOptions = [],
  onChange = _.noop,
  value,
  title = "Size",
  sideNote = "Required",
  brandColor = "red",
  T,
  appStyles,
  currencySymbol = "$",
  refEl = _.noop,
  hasErrors,
}) => (
  <Card refEl={refEl} appStyles={appStyles} hasError={hasErrors}>
    <Card.Header>
      <Card.Header.Title appStyles={appStyles}>{T(title)}</Card.Header.Title>
      <Card.Header.SideNote appStyles={appStyles} color={brandColor}>
        <strong>{sideNote}</strong>
      </Card.Header.SideNote>
    </Card.Header>
    <Card.Content>
      <Card.Content.RadioGroup
        name="size"
        value={value}
        onChange={onChange}
        appStyles={appStyles}
        wrap
      >
        {_.map(
          _.filter(sizeOptions, (sizeOption) => sizeOption.menuSizeRef),
          (sizeOption) => (
            <Card.Content.RadioOption
              iconSize={16}
              iconInnerSize={16}
              pointColor={brandColor}
              value={sizeOption.menuSizeId}
              key={sizeOption.menuSizeId}
            >
              <span>{sizeOption.menuSizeRef.name}</span>
              <span style={{whiteSpace: "nowrap"}}>
                {currencySymbol}
                {sizeOption.price.toFixed(appStyles.pricesFixedPoint)}
              </span>
            </Card.Content.RadioOption>
          ),
        )}
      </Card.Content.RadioGroup>
    </Card.Content>
  </Card>
);
